<template>
  <v-container>
    <v-progress-circular indeterminate/>
  </v-container>
</template>

<script>
export default {
  name: 'OAuth',
  props: {
    provider: String,
  },
  mounted() {
    const { code, error } = this.$route.query

    if (code) {
      if (this.$store.state.user) {
        //
      } else {
        this.$store.state.ky.post('oauth/login', {
          json: {
            authorizationCode: code,
            provider: this.provider,
          },
        })
            .json()
            .then(user => {
              this.$store.commit('setUser', user)
              this.$router.replace({ name: 'Energy' })
            })
      }
    }

    console.error(error)
  },
}
</script>
